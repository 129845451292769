import React from 'react';
import ReactRoutes from './ReactRoutes';
import Provider from './Store/StoreProvider';
import 'antd/dist/antd.min.css';
import ToasterComponent from './Components/Toaster';

function App() {
    return (
        <div>
            <Provider>
                <ReactRoutes />
                <ToasterComponent />
            </Provider>
        </div>
    );
}

export default App;

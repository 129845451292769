import React, { Suspense, lazy } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

const HtmlPage = lazy(() => import('./Pages/HtmlPage'));
const InsightDashboardPage = lazy(() => import('./Pages/InsightDashboardPage'));
const UpgradeMemberShipComponent = lazy(() => import('./Components/UpgradeMemberShipComponent'));
const StandardDashboard = lazy(() => import('./Pages/StandardDashboard'));
const ProfessionalDashboard = lazy(() => import('./Pages/ProfessionalDashboard'));

function ReactRoutes() {
    return (
        <Router>
            <Suspense fallback="">
                <Routes>
                    <Route exact path="/dashboard/v2" element={<HtmlPage />} />
                    <Route exact path="/v2/standard/dashboard" element={<StandardDashboard />} />
                    <Route
                        exact
                        path="/v2/professional/dashboard"
                        element={<ProfessionalDashboard />}
                    />
                    <Route
                        exact
                        path="/upgrade/membership"
                        element={<UpgradeMemberShipComponent />}
                    />
                    <Route exact path="*" element={<InsightDashboardPage />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default ReactRoutes;
